jQuery(document).ready(function () {
    loadProducts();
    initMapFilter();
});

function loadProducts(){
    $('#load-more-products').on('click', function () {
        var button = $(this);
        var page = button.data('page');
        var ajaxUrl = ajaxurl; // WordPress AJAX URL

        $.ajax({
            url: ajaxUrl,
            type: 'POST',
            data: {
                action: 'load_more_products',
                page: page,
            },
            success: function (data) {
                if ($.trim(data) !== '') {
                    // Append the new products to the product list
                    $('.woocommerce-shop-products').append(data);

                    // Update the button to load the next page of products
                    button.data('page', page + 1);
                } else {
                    // No more products, disable the button
                    button.text('No More Products').prop('disabled', true);
                }
            },
            error: function () {
                console.log('Error loading products.');
            }
        });
    });
}




// Event listener for the acceptance button
$('#accept-dsgvo').on('click', function () {
    const acceptanceContainer = $('#acceptanceContainer');
    acceptanceContainer.hide();
    initMap();
});
function initMap(){
    jQuery.ajax({
        type: 'POST',
        url: ajax.url,
        dataType: 'json',
        data: {
            action: 'get_all_post_coordinates',

        },
        success: function (data, textStatus, XMLHttpRequest) {
            map = new google.maps.Map(document.getElementById('mapDiv'), {
                center: { lat: 50.5645135, lng: 9.5794969 },
                zoom: 6
            });

            setMarkers(data);
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            console.log(errorThrown);
        }
    })
}

const defaultMarker = {
    path: "M13.3,2.25A6.507,6.507,0,0,0,6.75,8.671C6.75,13.487,13.3,20.6,13.3,20.6s6.552-7.109,6.552-11.925A6.507,6.507,0,0,0,13.3,2.25Zm0,8.715a2.294,2.294,0,1,1,2.34-2.293A2.295,2.295,0,0,1,13.3,10.965Z",
    fillColor: '#E29C00',
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 1.5,
};

let activeCategories = [];

function setMarkers(coordinates){
    let markers = [];
    var bounds = new google.maps.LatLngBounds();

    coordinates.forEach(function (marker, i) {
        var position = new google.maps.LatLng(marker.latitude, marker.longitude);
        var contentString = '<div id="content">'+'</div>';
        var infowindow		=	new google.maps.InfoWindow({
            content: contentString
        });
        var new_marker = new google.maps.Marker({
            position: position,
            icon: defaultMarker,
            map: map,
            title: marker.title,
            animation: google.maps.Animation.DROP
        })
        new_marker.addListener('mouseover', function () {
            new_marker.setIcon({
                path: defaultMarker.path,
                fillColor: '#01411F',
                fillOpacity: defaultMarker.fillOpacity,
                strokeWeight: defaultMarker.strokeWeight,
                rotation: defaultMarker.rotation,
                scale: defaultMarker.scale
            });
        });
        new_marker.addListener('mouseout', function () {
            new_marker.setIcon(defaultMarker); // Revert back to the default marker
        });

        infowindow.setContent('<div class="map-tool-tip">'+'<p class="map-tool-title">'+ marker.title +'</p><p><a id= "map-btn" href="'+ marker.permalink +'" target="_blank">Zum Standort</a></p></div>');
        google.maps.event.addListener(new_marker, 'click', (function(new_marker, i) {
            return function() {
                infowindow.open(map, new_marker);
            }
        })(new_marker, i));

        markers.push(new_marker);

    });
    // removeMarkers(markers);
    map.setZoom(6)
}

function removeMarkers(markers) {
    console.log(markers);
}

function initMapFilter(){
    $(".category-checkbox").change(function() {
        let categoryId = $(this).data('category-id');
        let index = activeCategories.indexOf(categoryId);
        if (this.checked) {
            if (index === -1) {
                activeCategories.push(categoryId);
            }
        } else {
            if (index !== -1) {
                activeCategories.splice(index, 1);
            }
        }
        initMapChange(activeCategories);
    });
}

function initMapChange(activeCategories = []){

    jQuery.ajax({
        type: 'POST',
        url: ajax.url,
        dataType: 'json',
        data: {
            action: 'get_post_coordinates',
            categories: activeCategories
        },
        success: function (data, textStatus, XMLHttpRequest) {
            map = new google.maps.Map(document.getElementById('mapDiv'), {
                center: { lat: 50.5645135, lng: 9.5794969 },
                zoom: 6
            });
            setMarkers(data);
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            console.log(errorThrown);
        }
    })
}